import { Controller } from '@hotwired/stimulus'
import { Carousel } from 'bootstrap'

export default class extends Controller {
  connect() {
    // Add appropriate carousel classes and id
    const stories = this.element.querySelectorAll('.companion-stories ul')
    stories.forEach((story, i) => {
      if (i === 0) {
        story.classList.add('carousel-item', 'active')
      } else {
        story.classList.add('carousel-item')
      }
      story.setAttribute('id', i)
    })

    const companionImages = this.element.querySelectorAll('.companion-stories .carousel action-text-attachment')
    companionImages.forEach((image, i) => {
      if (i === 0) {
        image.setAttribute('class', 'carousel-item active')
      } else {
        image.setAttribute('class', 'carousel-item')
      }
    })

    // Remove classes and elements that affect carousel
    const trixContent = this.element.querySelectorAll('.companion-stories .carousel .trix-content')
    trixContent.forEach(element => element.removeAttribute('class'))

    const brTags = this.element.querySelectorAll('.companion-stories br')
    brTags.forEach(br => br.remove())

    // Only for Mobile Carousel
    const gallery = this.element.querySelectorAll('.companion-stories-images .carousel-mobile .attachment-gallery')
    if (gallery.length) {
      gallery[0].removeAttribute('class')
    }

    // Add yellow border to the first story of desktop carousel
    const firstImage = this.element.querySelectorAll('.carousel-desktop img')[0]
    if (firstImage) {
      firstImage.style.border = '2px solid #F8F5BE'
      firstImage.style.boxShadow = '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
    }
  }

  nextStory() {
    // Desktop Carousel
    const currentStoryId = this.element.querySelectorAll('.carousel-text .carousel-inner .active')[0].id
    const nextStoryId = currentStoryId === '2' ? 0 : parseInt(currentStoryId) + 1
    this.addStyle(nextStoryId)
    // Mobile Carousel
    const carousel = new Carousel(document.querySelector('.carousel'))
    carousel.next()
  }

  prevStory() {
    // Desktop Carousel
    const currentStoryId = this.element.querySelectorAll('.carousel-text .carousel-inner .active')[0].id
    const nextStoryId = currentStoryId === '0' ? 2 : parseInt(currentStoryId) - 1
    this.addStyle(nextStoryId)
    // Mobile Carousel
    const carousel = new Carousel(document.querySelector('.carousel'))
    carousel.prev()
  }

  addStyle(nextStoryId) {
    const images = this.element.querySelectorAll('.carousel-desktop img')
    images.forEach(image => {
      image.style.border = ''
    })
    images[nextStoryId].style.border = '2px solid #F8F5BE'
    images[nextStoryId].style.boxShadow = '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
  }

  addSwipeEvents() {
    this.element.addEventListener('touchstart', handleTouchStart, false)
    this.element.addEventListener('touchmove', handleTouchMove, false)

    var xDown = null

    function getTouches(evt) {
      return evt.touches || evt.originalEvent.touches
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0]
      xDown = firstTouch.clientX
    }

    function handleTouchMove(evt) {
      if (!xDown) {
        return
      }

      var xUp = evt.touches[0].clientX

      var xDiff = xDown - xUp

      if (xDiff > 5) {
        const carousel = new Carousel(document.querySelector('.carousel'))
        carousel.next()
      }
      if (xDiff < -5) {
        const carousel = new Carousel(document.querySelector('.carousel'))
        carousel.prev()
      }
      xDown = null
    }
  }
}
