/* eslint no-console:0 */

import Rails from 'rails-ujs'
import ReactRailsUJS from 'react_ujs'

import 'bootstrap'
import '../src/www.scss'

// Stimulus
import { Application as Stimulus } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

require.context('../images/www', true)

// Rails UJS
Rails.start()

ReactRailsUJS.useContext(require.context('components/shared', true))

const stimulusApp = Stimulus.start()
const context = require.context('controllers/www', true, /\.js$/)
stimulusApp.load(definitionsFromContext(context))

const sharedContext = require.context('controllers/shared', true, /\.js$/)
stimulusApp.load(definitionsFromContext(sharedContext))

window.stimulusApp = stimulusApp
