import Rails from 'rails-ujs'

export function turboFetchCollection(path, target, params = {}) {
  const searchParams = new URLSearchParams(params)
  searchParams.append('target', target)

  turboRequest(`${path}?${searchParams}`)
}

export function turboRequest(url, options) {
  const { method = 'GET', body } = options || {}
  Rails.ajax({
    url: url,
    type: method,
    data: body,
    dataType: 'text/vnd.turbo-stream.html',
    success: data => {
      window.Turbo.renderStreamMessage(data)
    }
  })
}

export function formElementData(form, params) {
  let formData = new FormData(form)
  const formDataObj = {}

  // Converts FormData entries into a structured object and appends each key-value pair to URLSearchParams, handling multiple values per key.
  formData.forEach((value, key) => {
    if (formDataObj[key]) {
      if (!Array.isArray(formDataObj[key])) {
        formDataObj[key] = [formDataObj[key]]
      }
      formDataObj[key].push(value)
    } else {
      formDataObj[key] = value
    }
  })


  Object.keys(formDataObj).forEach(key => {
    if (Array.isArray(formDataObj[key])) {
      formDataObj[key].forEach(value => {
        params.append(key, value)
      })
    } else {
      params.append(key, formDataObj[key])
    }
  })
  return params
}
