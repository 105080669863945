import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Controller } from '@hotwired/stimulus'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'

export default class extends Controller {
  static targets = ['mobileOrganization', 'organization', 'type', 'tagFilter', 'locationFilter']

  connect() {
    const baseUrl = this.data.get('url')
    this.currentDate = this.data.get('current-date')
    this.eventsUrl = baseUrl
    this.initializeCalendar('', this)
  }

  onOrganizationChange() {
    if (this.data.get('new-tab')) {
      window.location = this.organizationTarget.value + '?iframe=true'
    } else {
      window.location = this.organizationTarget.value
    }
  }

  onMobileOrganizationChange() {
    window.location = this.mobileOrganizationTarget.value
  }

  filterTags() {
    var searchParams = new URLSearchParams()

    const tagId = this.tagFilterTarget.value
    if (tagId) {
      searchParams.append('tag', tagId)
      this.initializeCalendar(searchParams, this)
    }
  }

  onLocationChange() {
    var searchParams = new URLSearchParams()

    const type = this.locationFilterTarget.value
    if (type) {
      searchParams.append('location_type', type)
      this.initializeCalendar(searchParams, this)
    }
  }

  initializeCalendar(params, that) {
    const mdWidth = 768
    var calendarEl = document.getElementById('calendar')

    //See: https://fullcalendar.io/docs/getting-started
    var calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      initialDate: that.currentDate,
      events: function (data, successCallback) {
        var searchParams = new URLSearchParams()

        searchParams.append('date[from]', data.startStr)
        searchParams.append('date[to]', data.endStr)
        const url = that.eventsUrl + '?' + searchParams + '&' + params

        fetch(url, { headers: { 'Content-Type': 'application/json', Accept: 'application/json' } })
          .then(response => response.json())
          .then(events => successCallback(events))
      },
      contentHeight: 'auto',
      dayMaxEvents: 4,
      moreLinkContent: ({ num }) => `Show more (${num})`,
      initialView: window.innerWidth < mdWidth ? 'listWeek' : 'dayGridMonth',
      fixedWeekCount: false,
      stickyHeaderDates: true,
      headerToolbar:
        window.innerWidth < mdWidth
          ? {
              left: 'prev',
              center: 'title',
              right: 'next'
            }
          : {
              left: 'today prev,next',
              center: 'title',
              right: 'dayGridMonth,listWeek'
            },
      buttonText: {
        month: 'Month',
        list: 'List',
        today: 'Today'
      },
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: 'short'
      },
      listDayFormat: {
        day: 'numeric',
        weekday: 'short'
      },
      views: {
        listWeek: {
          displayEventTime: false
        }
      },
      listDaySideFormat: false,
      timeZone: 'local',
      slotLabelFormat: {
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        hour12: false,
        timeZoneName: 'short'
      },
      eventClick: function (data) {
        if (data.event.extendedProps.status === 'canceled') {
          return
        }
        let url
        if (data.event.extendedProps.externalURL) {
          url = data.event.extendedProps.externalURL
        } else {
          url = data.event.extendedProps.internalURL
        }

        if (that.data.get('new-tab') || data.event.extendedProps.externalURL) {
          window.open(url, '_blank')
        } else {
          window.location = url
        }
      },
      // See: https://fullcalendar.io/docs/classname-input
      eventClassNames: function (data) {
        let event = data.event
        let eventStatus = event.extendedProps.status

        if (eventStatus === 'canceled') {
          return [`status-${eventStatus}`]
        } else {
          return []
        }
      },
      // See: https://fullcalendar.io/docs/content-injection
      eventContent: function (data) {
        let viewType = data.view.type
        let event = data.event
        let eventStatus = event.extendedProps.status
        let title = eventStatus === 'canceled' ? `<strong>Canceled</strong> ${event.title}` : `${event.title}`

        if (viewType === 'listWeek') {
          return {
            html: `<strong>${moment(event.start).format('h:mma')}</strong> ${title}`
          }
        } else if (viewType === 'dayGridWeek' || viewType === 'dayGridMonth') {
          return {
            html: `
              <div class='fc-event-title' id=event-${event.id}>
                <strong>${moment(event.start).format('h:mma')}</strong> ${title}
              </div>
            `
          }
        } else {
          console.error(`Error Rendering view for: ${viewType}`)
        }
      }
    })

    calendar.render()
  }
}
