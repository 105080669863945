import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'concernField',
    'wellbeing',
    'reimbursementField',
    'expensesCents',
    'connectedField',
    'notConnectedField'
  ]

  connect() {
    const el = this.element

    if (this.hasWellbeingTarget) {
      const wellBeingValue = this.wellbeingTarget.value
      this.wellBeingChanged(wellBeingValue)
    }
    if (this.hasReimbursementFieldTarget) {
      this.handleReimbursementChange(el.querySelector("[id$='_payment_details_reimbursement']").checked)
      this.expensesCentsTarget.addEventListener('keypress', e => {
        if (!e.key.match(/[.0-9]/)) {
          e.preventDefault()
        } // restrict input to numbers and decimals
      })
    }
    if (this.hasConnectedFieldTarget) {
      this.handleStatusChange(el.querySelector("[id$='_status_completed']").checked)
    }
  }

  wellBeingChanged(wellbeing) {
    if (wellbeing === 'concerned') {
      this.concernFieldTarget.classList.remove('d-none')
    } else {
      this.concernFieldTarget.classList.add('d-none')
    }
  }

  handleWellBeingChanged = ({ target }) => {
    this.wellBeingChanged(target.value)
  }

  reimbursementChanged = ({ target }) => {
    this.handleReimbursementChange(target.value)
  }

  handleReimbursementChange(option) {
    if (option === true || option === 'reimbursement') {
      this.reimbursementFieldTarget.classList.remove('d-none')
    } else {
      this.reimbursementFieldTarget.classList.add('d-none')
      this.expensesCentsTarget.value = null
    }
  }

  statusChanged = ({ target }) => {
    this.handleStatusChange(target.value)
  }

  handleStatusChange(option) {
    if (option === true || option === 'completed') {
      this.connectedFieldTarget.classList.remove('d-none')
      this.notConnectedFieldTarget.classList.add('d-none')
    } else {
      this.connectedFieldTarget.classList.add('d-none')
      this.notConnectedFieldTarget.classList.remove('d-none')
    }
  }
}
