import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['wellbeing', 'concernFields', 'concernHelpfulInfo', 'provideReferral', 'referralTo']

  connect() {
    if (this.hasWellbeingTarget) {
      this.wellBeingChanged(this.wellbeingTarget.value)
    }

    if (this.hasProvideReferralTarget) {
      this.provideReferralChanged(this.provideReferralTarget.value)
    }
  }

  wellBeingChanged(wellbeing) {
    const customForm = this.concernFieldsTarget.classList.contains('custom-rsvp-story-form')

    if (wellbeing === 'concerned' || (customForm && wellbeing === 'okay')) {
      this.concernFieldsTarget.classList.remove('d-none')
      if (this.concernHelpfulInfoTarget) {
        this.concernHelpfulInfoTarget.classList.remove('d-none')
      }
    } else {
      this.concernFieldsTarget.classList.add('d-none')
      if (this.concernHelpfulInfoTarget) {
        this.concernHelpfulInfoTarget.classList.add('d-none')
      }
    }
  }

  handleWellBeingChanged = ({ target }) => {
    this.wellBeingChanged(target.value)
  }

  provideReferralChanged(provideReferral) {
    if (provideReferral === 'true') {
      this.referralToTarget.classList.remove('d-none')
    } else {
      this.referralToTarget.classList.add('d-none')
    }
  }

  handleProvideReferralChanged({ target }) {
    this.provideReferralChanged(target.value)
  }
}
