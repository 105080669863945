import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // Add appropriate carousel classes and id
    const logos = document.querySelectorAll(
      '.local-companions .carousel-mobile action-text-attachment'
    )
    logos.forEach((logo, i) => {
      if (i === 0) {
        logo.setAttribute('class', 'carousel-item active')
      } else {
        logo.setAttribute('class', 'carousel-item')
      }
    })
    // Remove classes and elements that affect carousel
    const gallery = document.querySelectorAll(
      '.local-companions .carousel-mobile .carousel-inner div .attachment-gallery'
    )
    if (gallery.length) {
      gallery[0].removeAttribute('class')
    }
  }
}
